<template>
  <div class="wrapper">
    <header>加工单选客户</header>
    <div class="split">
      <div>
        <el-input v-model="inputVal3" @input="hh_inputChange" placeholder="本页搜索"></el-input>
      </div>
      <div>
        <span @click="qh_display_Mode" class="text-xl text-amber-500">
          <span v-if="displayMode =='表格显示'" class=" text-amber-500"><el-icon ><List /></el-icon>卡片</span>
          <span v-else class=" text-teal-500"><el-icon ><Grid /></el-icon>表格</span>
        </span>
      </div>
    </div>

    <!-- 中间滚动区域 -->
    <section>
      <div class="mybody">
        <lzqTable v-if="displayMode =='表格显示'"
          :tableData_hh_dh="tableData_xm_gx"
          mytitle="加工客户基础资料表头"
          :IsSelection="false"
          RowKey="item3"
          @tableRowClick="tableRowClick($event)"
          @handleSelectionChange="handleSelectionChange($event)">
        </lzqTable>
        <div v-if="displayMode =='卡片显示'">
          <el-card class="box-card" v-for="(item,index) in tableData_xm_gx" :key="index" @click="tableRowClick(item)" style="margin:6px;">
            <template #header>
              <div class="card-header">
                <span class="text-3xl text-amber-500">{{item.item1}}</span>
              </div>
            </template>
            <!-- <span v-for="(list,indx) in item" :key="indx" class="text item">
              <span v-if="indx>='item2' && indx<='item3'" class="text-2xl text-teal-500">{{ list }}/</span>
            </span> -->
          </el-card>
      </div>
      </div>
    </section>

    <!-- 中间添加客户资料弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_kfzl" width="90%">
      <el-row justify="center">
        <el-col :span="10"
          ><span class="mybt">{{ formData.qxxm }}加工客户</span></el-col
        >
      </el-row>
      <el-form>
        <div style="margin-top: 10px; margin-bottom: 10px">
          <el-form-item label="客户名称" label-width="90px"
            ><el-input v-model="formData.khmc" style="width: 200px" />
          </el-form-item>
          <el-form-item label="送货地址" label-width="90px"
            ><el-input v-model="formData.shdz" style="width: 200px" />
          </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="10"
          ><el-button type="success" @click="my_add_xg" :loading="myloading"
            >保存{{ formData.qxxm }}</el-button
          ></el-col
        >
      </el-row>
    </el-dialog>
    <!-- 中间添加客户资料弹窗结束 -->

    <!-- 底部开始 -->
    <footer>
      <div class="mybutton">
        <el-row class="mb-4">
          <!-- <el-button type="success" @click="btn_add_xg_del('新增')">新增客户</el-button>
          <el-button type="warning" @click="btn_add_xg_del('修改')">修改</el-button>
          <el-button type="danger" @click="btn_add_xg_del('删除')">删除</el-button> -->
          <el-button type="info" @click="toHome">主页</el-button>
        </el-row>
      </div>
    </footer>
    <!-- 底部结束 -->
  </div>
</template>

<script setup>
import myGtdatafun from "../../myapi/myGtdatafun.js";
import dateglobalfun from "../../../dateglobalfun.js"; //日期函数
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import lzqTable from "../truecomponents/lzqTable.vue"; //引入自定义组件
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const router = useRouter();
const dialogFormVisible_kfzl = ref(false);
const myloading = ref(false);
const displayMode = ref("卡片显示");
const inputVal3 = ref("");

const formData = ref({//表格生成
  rq: "",
  dzkhid: "", //订制产品ID
  dzcpid: "", //订制产品ID
  jgkhid: "", //加工产品ID
  jgcpid: "", //加工产品ID
  azdz: "", //安装地址
  azqy: "", //安装区域
  bz: "", //备注
  bm: "", //编码
  clry: "", //测量人员
  dh: "", //单号
  dj: "", //单价
  dw: "", //单位
  dh: "", //电话
  gd: "", //高度
  gg: "", //规格
  je: "", //金额
  jsr: "", //经手人
  kdrq: "", //开单日期
  khdz: "", //产品地址
  khdh: "", //产品电话
  khmc: "", //产品名称
  kd: "", //宽度
  lldh: "", //联络电话
  lxr: "", //联系人
  lxrdh: "", //联系人电话
  mlhclmc: "", //面料或材料名称
  pm: "", //品名
  spbm: "", //商品编码
  spqm: "", //商品全名
  sjazrq: "", //实际安装日期
  sl: "", //数量
  shdz: "微信", //送货地址
  shdz: "", //送货地址
  xdrq: "", //下单日期
  xh: "", //序号
  yqazrq: "", //要求安装日期
  zdr: "", //制单人
  cztj: "tjjczl", //操作条件
  yhmc: "", //token
  qxxm: "", //权限项目
  id: "1", //
  endrq: "", //结束日期
});

const qh_display_Mode = () =>{
  if (displayMode.value=='表格显示') {
    displayMode.value='卡片显示'
  }else{
    displayMode.value='表格显示'
  }
}

onMounted(() => {
  //页面进入时
  formData.value.yhmc = localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.xm = localStorage.getItem(myGtdatafun.XMMC + "xm"); //姓名
  formData.value.gjgx = localStorage.getItem(myGtdatafun.XMMC + "gx"); //工价工序
  formData.value.cztj = "手机端进入界面先获取加工客户基础资料";
  khjczl_get();
  // setTimeout(() => {
  //   formData.value.cztj = "发货单获取控价名称";
  //   khjczl_get();
  // }, 500);
});

const btn_add_xg_del = (tj) => {
  //三个按钮的操作
  switch (tj) {
    case "新增":
      formData.value.khmc = "";
      formData.value.shdz = "";
      formData.value.qxxm = "新增";
      dialogFormVisible_kfzl.value = true; //显示弹窗
      break;
    case "修改":
      if (formData.value.khmc == "") {
        return;
      }
      formData.value.qxxm = "修改";
      dialogFormVisible_kfzl.value = true; //显示弹窗
      break;
    case "删除":
      if (formData.value.khmc == "") {
        return;
      }
      my_del(); //删除
      break;
  }
};

const my_del = () => {
  //删除一行
  ElMessageBox.confirm(
    "[" + formData.value.khmc + "/" + formData.value.shdz + "/" + "]修改或删除?",
    "修改或删除?",
    { confirmButtonText: "确认删除", cancelButtonText: "取消删除", type: "error" }
  )
    .then(() => {
      formData.value.cztj = "手机端删除一行加工客户资料";
      myGtdatafun
        .MygettableData("html_dysj_ZSG.php", formData.value)
        .then(function (response) {
          console.log(response);
          location.reload(); //重新加载本页
        });
      tableData_xm_gx.splice(
        tableData_xm_gx.findIndex((e) => e.item3 === formData.value.id),
        1
      ); // 将删除id等于1的选项
    })
    .catch(() => {
      console.log("取消删除");
      // dialogFormVisible_kfzl.value = !dialogFormVisible_kfzl.value;
      // formData.value.khmc=row.item1
      // formData.value.shdz=row.item2
    });
};

const my_add_xg = () => {
  //保存新增和修改
  console.log("my_add_xg", formData.value);
  if (formData.value.khmc == "") {
    ElMessage({ message: "客户名称不能为空,保存不成功!", type: "error" });
    return;
  }
  //客户名称重复了,不保存
  let cansavekh = "yes";
  tableData_xm_gx.forEach((ite, index) => {
    if (ite.item1 == formData.value.khmc) {
      ElMessage({ message: "客户名称重复了,保存不成功!", type: "error" });
      cansavekh = "no";
    }
  });
  if (cansavekh == "no") {
    dialogFormVisible_kfzl.value = false; //隐藏弹窗
    formData.value.khmc = "";
    formData.value.shdz = "";
    return;
  }
  myloading.value = true;
  if (formData.value.qxxm == "新增") {
    formData.value.cztj = "手机端新增加工客户资料";
  } //==要注意
  if (formData.value.qxxm == "修改") {
    formData.value.cztj = "手机端修改加工客户资料";
  }
  khjczl_get();
  dialogFormVisible_kfzl.value = false;
  formData.value.khmc = "";
  formData.value.shdz = "";
};

const handleSelectionChange = (arr) => {
  //选择复选框
  console.log("handleSelectionChange[]", arr);
  // let ids = "";
  arr.forEach((item, index) => {
    formData.value.khmc = item.item1;
    formData.value.shdz = item.item2;
    formData.value.id = item.item3;
    // if (index == 0) {
    //   ids = item.item3;
    // } else {
    //   ids = ids + "," + item.item3;
    // }
  });
  console.log("handleSelectionChange", formData.value);
};

const tableRowClick = (row) => {
  //点击一行跳转
  console.log("跳转到另一页");
  router.push({
    path: "a_jgd_2_2",
    query: {
      my_row: JSON.stringify(row),
    },
  });
  // if (row.item1 == '查无资料') return
  // ElMessageBox.confirm(
  //   "[" + row.item1 + "/" + row.item2 + "/" + "]修改或删除?",
  //   "修改或删除?",
  //   {
  //     confirmButtonText: "删除",
  //     cancelButtonText: "修改",
  //     type: "error",
  //   }
  // )
  //   .then(() => {
  //     formData.value.id = row.item3;
  //     formData.value.cztj = "手机端删除一行加工客户资料";
  //     myGtdatafun
  //       .MygettableData("html_dysj_ZSG.php", formData.value)
  //       .then(function (response) {
  //         console.log(response);
  //         location.reload(); //重新加载本页
  //       });
  //     tableData_xm_gx.splice(
  //       tableData_xm_gx.findIndex((e) => e.item8 === row.item8),
  //       1
  //     ); // 将删除id等于1的选项
  //   })
  //   .catch(() => {
  //     console.log('这是修改',row,formData.value);
  //     dialogFormVisible_kfzl.value = !dialogFormVisible_kfzl.value;
  //     formData.value.khmc=row.item1
  //     formData.value.shdz=row.item2
  //   });
};

const khjczl_get = async () => {
  //提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value)
    .then(function (response) {
      console.log(formData.value.cztj, response);
      myloading.value = false;
      if (response.length <= 0) {
        return;
      }
      formData.value.qxxm = "";
      tableData_xm_gx.length = 0;
      tableData_xm_gx_base.length = 0;
      response.forEach((item) => {
        tableData_xm_gx.push(item);
        tableData_xm_gx_base.push(item);
      });
    })
    .catch(function (error) {});
};

const toHome = () => {
  router.push("/");
};
//本页面筛选
const hh_inputChange = async (e) => {
  tableData_xm_gx.length = 0; //先清空显示的数组
  tableData_xm_gx_base.forEach((item, index) => {
    if (
      item.item1.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item2.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item3.indexOf(inputVal3.value) >= 0
    ) {
      tableData_xm_gx.push(item); //再把选到的加入显示数组
    }
  });
};
</script>
<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header,
footer {
  height: 40px;
  line-height: 40px;
  background-color: #d8d8d8;
  text-align: center;
}

section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* winphone8和android4+ */
  -webkit-overflow-scrolling: touch;
  /* ios5+ */
}

.mybutton {
  display: flex;
  justify-content: center;
}

:deep(.el-collapse-item__header) {
  color: red;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-form-item__label) {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-collapse-item__content) {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.mybt {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
}

:deep(.el-carousel__container) {
  height: 380px;
}

img {
  height: 380px;
}

.split {
  margin: 6px; margin-bottom: 10px; display: flex;
  justify-content: space-between; align-items: center;
}
</style>
